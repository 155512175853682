.left-msg-container {
    @apply flex;
}

.right-msg-container {
    @apply flex justify-end;
}

.left-msg {
    @apply bg-[#3E5EA9] rounded-tr-2xl rounded-bl-2xl rounded-br-2xl p-[0.8rem]
}

.right-msg {
    @apply bg-[#F5F5F5] rounded-tl-2xl rounded-bl-2xl rounded-br-2xl p-[0.8rem]
}


