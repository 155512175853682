@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.typing-block {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing-container .typing-dot {
  background-color: #3E5EA9;
}

.typing-dot {
  animation: typing-animation 1.5s infinite ease-in-out;
  -webkit-animation: typing-animation 1.5s infinite ease-in-out;
  border-radius: 100%;
  display: inline-block;
  height: 5px;
  width: 5px;
}

@keyframes typing-animation {
  0% {
    /* transform: translateY(0px);
    -webkit-transform: translateY(0px); */
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  28% {
    /* transform: translateY(-5px);
    -webkit-transform: translateY(-5px); */
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
  }
  44% {
    /* transform: translateY(0px);
    -webkit-transform: translateY(0px); */
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.typing-dot:nth-child(1) {
  animation-delay: 200ms;
  -webkit-animation-delay: 200ms;
}
.typing-dot:nth-child(2) {
  animation-delay: 300ms;
  -webkit-animation-delay: 300ms;
}
.typing-dot:nth-child(3) {
  animation-delay: 400ms;
  -webkit-animation-delay: 400ms;
}

.msg-left .btn-left {
  transform: translateX(-1.50rem);
  transition: 0.2s ease-in-out;
}

.msg-left:hover .btn-left {
  transform: translateX(1rem) rotate(360deg) scaleX(-1);
  color: #8a8a8a;
}

.msg-right .btn-right {
  transform: translateX(1.50rem) translateX(1rem) rotate(360deg) scaleX(-1);
  transition: 0.2s ease-in-out;
}

.msg-right:hover .btn-right {
  transform: translateX(-1rem);
  color: #3e5ea9;
}

.shake-right {
  animation: see-animation-right 1s ease-in-out;
  -webkit-animation: 1s see-animation-right 0.5s ease-in-out;
}

@keyframes see-animation-right {
  0% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }
  25% {
    transform: translateX(-20px);
    -webkit-transform: translateX(-20px);
  }
  50% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }
  75% {
    transform: translateX(-20px);
    -webkit-transform: translateX(-20px);
  }
  100% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }
}

.shake-left {
  animation: see-animation-left 1s ease-in-out;
  -webkit-animation: 1s see-animation-left 0.5s ease-in-out;
}

@keyframes see-animation-left {
  0% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }
  25% {
    transform: translateX(20px);
    -webkit-transform: translateX(20px);
  }
  50% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }
  75% {
    transform: translateX(20px);
    -webkit-transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }
}

