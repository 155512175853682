body{
  background: #f6f7fb !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


textarea:focus, input:focus, input[type]:focus, .uneditable-input:focus {   
  border-color: transparent;
  box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px transparent;
  outline: 0 none;
}

.css-iljtu2-MuiRating-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  font-size: 2.5rem !important;
  color: #faaf00;
  cursor: pointer;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  margin: 0 auto;
}

.rating_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.css-1ji7omv-MuiBackdrop-root {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: #f6f7fb !important;
  -webkit-tap-highlight-color: transparent;
  color: #fff;
  z-index: 1201;
}

.audio_div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2%;

}

.audio_img{
  width: 248px;
    height: 248px;
    left: 867.5px;
    top: 190px;
    border: 46.0126px solid rgba(121, 119, 119, 0.21);
    border-radius: 50%;
}

.audio_text{
  font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #FFFFFF;
    margin-top: 21%;
    text-align: center;
}

@media (max-width: 700px) {
  .audio_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 4%;
  }
}

