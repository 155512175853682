.provider_div {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
}

.waiting_heading {
  color: #202124;
  white-space: nowrap;
  text-align: center;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.01em;

}

.date_time {
  display: inline;
}

.date_time_text {
  width: 196px;
  height: 16px;
  margin: 0 4px;

  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 12.7294px;
  line-height: 16px;

  letter-spacing: -0.01em;

  color: #6c6c6c;
}

.waiting_patient {
  width: 80px;
  height: 80px;
  right: 814.22px;
  top: 335.71px;
  border-radius: 50%;
}

.waiitng_time_div {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  margin: 1% auto;
}

.waiitng_time_div_v_a {
  width: -moz-fit-content;
  width: fit-content;
  height: 35.38px;
  border-radius: 36.2464px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  margin: 0 auto;
  margin: 1% auto;
  padding: 4px 30px;
  margin-top: 5%;
}
.waiitng_time_div_c {
  width: -moz-fit-content;
  width: fit-content;
  height: 31.38px;
  border-radius: 36.2464px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  margin: 0 auto;
  margin: 1% auto;
  padding: 17px 30px;
}
.waiitng_time_text {
  width: fit-content;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  white-space: nowrap;
}

.waiting_info_div {
  width: 480px;
  height: auto;
  right: 583px;
  top: 246.35px;
  background: #fbfbfb;
  border: 1.11698px solid #eaeaea;
  border-radius: 9.57px;
  padding: 2.5rem;
}
/* input{
    background: gainsboro;
    margin:5%;
    width: 50%;
} */

.waiting_inputs_half {
  height: 44.68px;
  width: 146px;
}

.waiting_inputs_full {
  width: 100%;
  height: 44.68px;
}

.waiting_form {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.waiting_half_input {
  justify-content: space-between;
  width: 100%;
  margin-bottom: 7%;
}

.waiting_full_input {
  justify-content: space-around;
  width: 100%;
  margin-bottom: 7%;
}

.hr_tag {
  width: 111.5%;
  margin: 4% -6%;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 0.9em !important;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  text-transform: capitalize !important;
}

.rating_para {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 19.4101px;
  line-height: 25px;
  /* identical to box height */

  display: flex;
  align-items: flex-end;
  letter-spacing: -0.01em;

  color: #202124;
  justify-content: center;
}

.rating_btn {
  width: 150px;
  height: 50px;
  left: 0px;
  top: 5.85px;

  background: #3e5ea9;
  border-radius: 41.0335px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  margin: 5% auto;
  color: #ffffff;
}
.rating_skip {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #3e5ea9;
}

.rating_btn_con {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rating_input {
  width: 111%;
  margin-left: 10%;
  margin: 8% -12% 4%;
}

.rating_div {
  margin-top: 3%;
  margin-bottom: -3%;
}

@media (max-width: 700px) {
  .waiting_info_div {
    width: 100%;
    /* height: 558px; */
  }

  .waiting_form {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rating_input {
    width: 286px !important;
    margin-left: 0% !important;
    padding: 10% 0 4%;
  }

  .rating_div {
    margin-top: 3%;
    margin-bottom: -3%;
  }

  .waiting_inputs_full {
    width: 100%;
    height: 44.68px;
  }

  .waiting_info_div {
    width: 100%;
    /* height: 558px; */
  }

  .waiting_form {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .waiting_inputs_full {
    width: 100%;
    height: 44.68px;
  }

  .waiting_inputs_half {
    height: 44.68px;
    width: 46%;
  }
  .waiting_half_input {
    justify-content: space-around;
    width: 103%;
    margin-bottom: 7%;
    margin-left: -1%;
  }

  .rating_input {
    width: 100% !important;
    padding: 10% 0 4%;
    margin: 0 5%;
  }

  .rating_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .waiting_half_input {
    justify-content: space-around;
    width: 103%;
    margin-bottom: 7%;
    margin-left: -1%;
  }
}
