.scale-up-center {
	-webkit-animation: scale-up-center 0.8s ease-in-out infinite alternate both;
	        animation: scale-up-center 0.8s ease-in-out infinite alternate both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-24 23:31:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
    }
  }
  